const INITIAL_STATE = [
  {
    name: "Banana",
    description: "Yellow fruit",
    price: "1.00",
    pic: "https://images.immediate.co.uk/production/volatile/sites/30/2017/01/Bunch-of-bananas-67e91d5.jpg",
    sku: 1,
    rating: 2,
  },
  {
    name: "Orange",
    description: "Yummy orange fruit",
    price: "2.00",
    pic: "https://i5.walmartimages.ca/images/Enlarge/234/6_r/6000191272346_R.jpg",
    sku: 2,
    rating: 8,
  },
  {
    name: "Papaya",
    description: "Weird fruit with seeds",
    price: "3.00",
    pic: "https://5.imimg.com/data5/LJ/VK/MY-41533060/papaya-fruit-500x500.jpg",
    sku: 3,
    rating: 3,
  },
  {
    name: "Mango",
    description: "Best fruit in the world",
    price: "2.50",
    pic: "https://images.immediate.co.uk/production/volatile/sites/30/2018/08/mango-fee0d79-e1648560084294.jpg",
    sku: 4,
    rating: 10,
  },
];

// using code from Stephanie as a guide
// https://cpsc4552023.slack.com/archives/C059TFEB202/p1685214483128209?thread_ts=1685214189.854689&cid=C059TFEB202
const itemList = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "DELETE_ITEM":
      state = state.filter((item) => item.name !== action.payload);
      return state;
    case "EDIT_ITEMS":
      let index = state.findIndex((item) => item.name === action.payload.name);
      let updatedObject = { ...state[index], ...action.payload };
      let newState = [...state];
      newState[index] = updatedObject;
      return newState;
    case "DELETE_ALL_ITEMS":
      state = [];
      return state;
    case "SET_ITEMS":
      state = action.payload;
      return state;
    case "SORT_ALPHA":
      let sortedState = [...state].sort((a, b) => a.name.localeCompare(b.name));
      return sortedState;
    case "ADD_NEW_ITEM":
      return [...state, action.payload];
    default:
      return state;
  }
};

export default itemList;
