import Button from "./Button";
import { useState } from "react";

function Sidebar() {
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [itemPic, setItemPic] = useState("");
  const [itemSKU, setItemSKU] = useState("");
  const [itemRating, setItemRating] = useState("");

  const clearForm = () => {
    setItemName("");
    setItemDescription("");
    setItemPrice("");
    setItemPic("");
    setItemSKU("");
    setItemRating("");
  };

  return (
    <>
      <form>
        <label htmlFor="name">Item Name:</label> <br />
        <input
          type="text"
          id="name"
          name="name"
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
        />
        <br />
        <label>Description:</label>
        <br />
        <input
          type="text"
          id="description"
          value={itemDescription}
          name="description"
          onChange={(e) => setItemDescription(e.target.value)}
        />
        <br />
        <label>Price:</label>
        <br />
        <input
          type="text"
          id="price"
          value={itemPrice}
          name="price"
          onChange={(e) => setItemPrice(e.target.value)}
        />
        <br />
        <label>Item image (url):</label>
        <br />
        <input
          type="text"
          id="pic"
          value={itemPic}
          name="pic"
          onChange={(e) => setItemPic(e.target.value)}
        />
        <br />
        <label>SKU:</label>
        <br />
        <input
          type="text"
          id="sku"
          value={itemSKU}
          name="sku"
          onChange={(e) => setItemSKU(e.target.value)}
        />
        <br />
        <label>Rating:</label>
        <br />
        <input
          type="text"
          id="rating"
          value={itemRating}
          name="rating"
          onChange={(e) => setItemRating(e.target.value)}
        />
        <br />
        <br />
        <input
          type="button"
          id="clear"
          value="Clear All Inputs"
          onClick={clearForm}
          name="clear"
        />
        <Button
          itemName={itemName}
          itemDescription={itemDescription}
          itemPrice={itemPrice}
          itemPic={itemPic}
          itemSku={itemSKU}
          itemRating={itemRating}
        />
      </form>
    </>
  );
}

export default Sidebar;
