import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewItem, sortAlpha } from "../actions/index.js";

export default function Button(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.reducer);
  const [highestRated, setHighestRated] = useState("n/a");
  const [average, setAverage] = useState("n/a");

  useEffect(() => {
    const fetchAverageRating = () => {
      fetch("https://cpsc455-achn-backend-server.onrender.com/averagerating", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((item) => {
          setAverage(item);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    const fetchHighestRated = () => {
      fetch("https://cpsc455-achn-backend-server.onrender.com/highestrated", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((item) => {
          setHighestRated(item);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    fetchAverageRating();
    fetchHighestRated();

    return () => {};
  }, [items]);

  const handleAdd = (name, description, price, pic, sku, rating) => {
    fetch("https://cpsc455-achn-backend-server.onrender.com/items", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ name, description, price, pic, sku, rating }),
    })
      .then((response) => response.json())
      .then((item) => {
        dispatch(
          addNewItem(
            item.name,
            item.description,
            item.price,
            item.pic,
            item.sku,
            item.rating
          )
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleAlpha = () => {
    fetch("https://cpsc455-achn-backend-server.onrender.com/alpha", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((item) => {
        dispatch(sortAlpha(item));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <button
        type="button"
        onClick={() =>
          handleAdd(
            props.itemName,
            props.itemDescription,
            props.itemPrice,
            props.itemPic,
            props.itemSku,
            props.itemRating
          )
        }
      >
        Add Item
      </button>
      <hr />
      <button type="button" onClick={() => handleAlpha()}>
        Sort by : Alphabet (ASC)
      </button>
      <hr />
      <b>Average Rating : </b>
      {Number(average).toFixed(2)} <br />
      <b>Highest Rating : </b>
      {highestRated}
    </div>
  );
}
