import { useDispatch } from "react-redux";
import { remove } from "../actions/index.js";

export default function IndividualDelete(props) {
  const dispatch = useDispatch();

  const handleDelete = (name) => {
    fetch("https://cpsc455-achn-backend-server.onrender.com/items/" + name, {
      method: "DELETE",
    })
      .then((item) => dispatch(remove(name)))
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <button type="button" onClick={() => handleDelete(props.itemName)}>
        Remove Item
      </button>
    </div>
  );
}
