import React, { useState } from "react";
import EditModal from "./EditModal";
import Modal from "./Modal";
import IndividualDelete from "./IndividualDelete";

function Card(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleOpenEditModal = () => {
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="item-card">
      <h1>{props.name}</h1>
      <p>
        <img src={props.pic} alt="fruit" />
      </p>
      <IndividualDelete itemName={props.name} />
      <div>
        <button onClick={handleOpenEditModal}>Edit..</button>
        <EditModal
          isOpen={editModalOpen}
          onClose={handleCloseEditModal}
          name={props.name}
          description={props.description}
          price={props.price}
          pic={props.pic}
          sku={props.sku}
          rating={props.rating}
        >
          <hr />
        </EditModal>
      </div>
      <div>
        <button onClick={handleOpenModal}>More details...</button>
        <Modal
          isOpen={modalOpen}
          onClose={handleCloseModal}
          name={props.name}
          description={props.description}
          price={props.price}
          sku={props.sku}
          rating={props.rating}
        >
          <hr />
        </Modal>
      </div>
    </div>
  );
}

export default Card;
