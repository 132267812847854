export const addNewItem = (name, description, price, pic, sku, rating) => {
  return {
    type: "ADD_NEW_ITEM",
    payload: { name, description, price, pic, sku, rating },
  };
};

export const remove = (name) => {
  return {
    type: "DELETE_ITEM",
    payload: name,
  };
};

export const removeAll = () => {
  return {
    type: "DELETE_ALL_ITEMS",
    payload: "",
  };
};

export const setItems = (items) => {
  return {
    type: "SET_ITEMS",
    payload: items,
  };
};

export const editItems = (name, description, price, pic, sku, rating) => {
  return {
    type: "EDIT_ITEMS",
    payload: { name, description, price, pic, sku, rating },
  };
};

export const sortAlpha = () => {
  return {
    type: "SORT_ALPHA",
    payload: "",
  };
};
