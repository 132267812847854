
import './App.css';
import Nav from './components/Nav';
import Sidebar from './components/Sidebar';
import Content from './components/Content';

function App() {
  return (
    <div className="App">
      <div className="Nav">
        <Nav/>
      </div>
      <div className="NotNav">
        <div className="Sidebar"><Sidebar/></div>
        <div className="NotSidebar"><Content/></div>
      </div>
    </div>
  );
}

export default App;
