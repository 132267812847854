import Card from "./Card";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setItems } from "../actions";

function Content() {
  const items = useSelector((state) => state.reducer);
  const dispatch = useDispatch();

  useEffect(() => {
    fetch("https://cpsc455-achn-backend-server.onrender.com/items")
      .then((response) => response.json())
      .then((data) => {
        dispatch(setItems(data));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [dispatch]);

  return (
    <div>
      <ul>
        <div className="content">
          {items.map((item) => (
            <Card
              key={crypto.randomUUID()}
              name={item.name}
              description={item.description}
              price={item.price}
              pic={item.pic}
              sku={item.sku}
              rating={item.rating}
            />
          ))}
        </div>
      </ul>
    </div>
  );
}

export default Content;
