import React from "react";
import { useState } from "react";
import { editItems } from "../actions";
import { useDispatch } from "react-redux";

const EditModal = ({
  isOpen,
  onClose,
  name,
  description,
  price,
  pic,
  sku,
  rating,
}) => {
  const dispatch = useDispatch();

  const [itemName, setItemName] = useState(name);
  const [itemDescription, setItemDescription] = useState(description);
  const [itemPrice, setItemPrice] = useState(price);
  const [itemPic, setItemPic] = useState(pic);
  const [itemSKU, setItemSKU] = useState(sku);
  const [itemRating, setItemRating] = useState(rating);

  if (!isOpen) {
    return null;
  }

  const handleEdit = (
    itemName,
    itemDescription,
    itemPrice,
    itemPic,
    itemSKU,
    itemRating
  ) => {
    fetch("https://cpsc455-achn-backend-server.onrender.com/items/", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        itemName,
        itemDescription,
        itemPrice,
        itemPic,
        itemSKU,
        itemRating,
      }),
    })
      .then((response) => response.json())
      .then((item) => {
        dispatch(
          editItems(
            itemName,
            itemDescription,
            itemPrice,
            itemPic,
            itemSKU,
            itemRating
          )
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="modal-background">
      <div className="modal">
        <h3>{itemName}</h3>
        <form>
          <br />
          <label>Description:</label>
          <br />
          <input
            type="text"
            id="description"
            value={itemDescription}
            name="description"
            onChange={(e) => setItemDescription(e.target.value)}
          />
          <br />
          <label>Price:</label>
          <br />
          <input
            type="text"
            id="price"
            value={itemPrice}
            name="price"
            onChange={(e) => setItemPrice(e.target.value)}
          />
          <br />
          <label>Item image (url):</label>
          <br />
          <input
            type="text"
            id="pic"
            value={itemPic}
            name="pic"
            onChange={(e) => setItemPic(e.target.value)}
          />
          <br />
          <label>SKU:</label>
          <br />
          <input
            type="text"
            id="sku"
            value={itemSKU}
            name="sku"
            onChange={(e) => setItemSKU(e.target.value)}
          />
          <br />
          <label>Rating:</label>
          <br />
          <input
            type="text"
            id="rating"
            value={itemRating}
            name="rating"
            onChange={(e) => setItemRating(e.target.value)}
          />
          <br />
        </form>
        <br />
        <button
          type="button"
          onClick={() =>
            handleEdit(
              itemName,
              itemDescription,
              itemPrice,
              itemPic,
              itemSKU,
              itemRating
            )
          }
        >
          Confirm Changes
        </button>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default EditModal;
