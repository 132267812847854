import React from "react";

const Modal = ({ isOpen, onClose, name, description, price, sku, rating }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-background" data-testid="modal">
      <div className="modal">
        <h4>Name: {name}</h4>
        <i>Description:</i> {description} <br />
        <i>Price:</i> ${price} <br />
        <i>SKU:</i> {sku} <br />
        <i>Rating:</i> {rating} <br />
        <br />
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
